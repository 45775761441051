import * as React from "react";
import { isTablet, isMobile } from "react-device-detect";
import EmbedYouTubeVideo from "../EmbedYouTubeVideo";

const isTouch = isMobile || isTablet;

export default function BusyWomanVideo() {
  const video = (
    <EmbedYouTubeVideo
      title="Look familiar?"
      url="https://youtu.be/qbdEC-dWCwM"
      wrapperHeight="100%"
      autoplay
    />
  );

  return (
    <div className="signup-busy-woman">
      <div className="text-container">
        <p className="description">
          <span className="accent">As a woman,</span> I bet you wear a lot of
          different hats! You're constantly taking care of everyone else so when
          it comes to prioritizing your own health and weight loss goals, you
          literally just don't have time! All those fad diets and quick fix
          programs out there promise you instant results, but it's just
          impossible to stick to them!{" "}
          {!isTouch &&
            "There's a better way! The BALANCED way! Real expectations, real results, better healthy habits, and a celebration that you NEVER have to start over on Monday again!"}
        </p>
      </div>
      <div className="video-container">{video}</div>
      {isTouch && (
        <div className="text-container">
          <p className="description">
            There's a better way! The BALANCED way! Real expectations, real
            results, better healthy habits, and a celebration that you NEVER
            have to start over on Monday again!
          </p>
        </div>
      )}
      {!isTouch && (
        <div className="text-container" style={{ minHeight: "40px" }}></div>
      )}
    </div>
  );
}
