import * as React from "react";

export default function HeroFoldBar() {
  return (
    <div className="hero-fold-bar">
      <div>
        <h1>
          <span className="gold-text">Find Your</span>
          <span className="gray-text"> Balance</span>
        </h1>
        <h2>
          Quick home workouts and balanced lifestyle guidance for busy women
        </h2>
      </div>
    </div>
  );
}
