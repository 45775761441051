import * as React from "react";
import loadable from "@loadable/component";
import { navigate } from "gatsby";
import scrollTo from "gatsby-plugin-smoothscroll";

import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import Hero from "../../components/SignUp/Hero";
import FoldBar from "../../components/FoldBar";
import FeatureGrid from "../../components/SignUp/FeatureGrid";

import * as ROUTES from "../../constants/routes";
import { useAuthUser } from "../../components/Session";
import HeroFoldBar from "../../components/HeroFoldBar";
import BusyWomanVideo from "../../components/SignUp/BusyWomanVideo";
import useMobileAppCheck from "../../hooks/useMobileAppCheck";

const Testimonials = loadable(() =>
  import("../../components/SignUp/Testimonials")
);
const PricingTiers = loadable(() =>
  import("../../components/SignUp/PricingTiers")
);
const FreeWorkout = loadable(() =>
  import("../../components/SignUp/FreeWorkout")
);
const SocialMediaSlide = loadable(() =>
  import("../../components/SocialMediaSlide")
);
const SignUpForm = loadable(() => import("../../components/SignUp/SignUpForm"));
const BackToBasixBlurb = loadable(() =>
  import("../../components/SignUp/BackToBasixBlurb")
);
const BalanceBanter = loadable(() =>
  import("../../components/SignUp/BalanceBanter")
);

function SignUpPage() {
  const authUser = useAuthUser();
  const [subscriptionType, setSubscriptionType] = React.useState("0");
  const isInApp = useMobileAppCheck();
  const [isClient, setClient] = React.useState(false);

  React.useEffect(() => {
    setClient(true);
  }, []);

  React.useEffect(() => {
    if (isClient && authUser) {
      navigate(ROUTES.HOME);
    }
  }, [authUser, isClient]);

  return (
    <div className="signup-page">
      {isInApp === false && (
        <>
          <Hero />
          <HeroFoldBar />
          <FeatureGrid />
          <BusyWomanVideo />
          <BackToBasixBlurb />
          <FoldBar
            backgroundColor="#00000080"
            regularText="FIND YOUR #BALANCE!"
            onSignUpClick={() => scrollTo("#signup-form")}
          />
          <FreeWorkout />
          <FoldBar
            regularText="JOIN THE #BALANCE COMMUNITY"
            onSignUpClick={() => scrollTo("#signup-form")}
          />
          <BalanceBanter />
          <FoldBar regularText="WHAT THE #BALANCE COMMUNITY HAS TO SAY!" />
          <Testimonials />
          <FoldBar
            regularText="BOOK YOUR FREE BALANCED LIFESTYLE CONSULTATION TODAY!"
            showBookNow={true}
            bookNowHref="https://calendly.com/kristielengel/balance-consultation"
          />
          <PricingTiers
            onSignUpClick={(subType) => {
              setSubscriptionType(subType);
              scrollTo("#signup-form");
            }}
          />
          <FoldBar backgroundColor="#000000" />
        </>
      )}
      <SignUpForm {...{ subscriptionType }} />
      {isInApp === false && (
        <>
          <FoldBar backgroundColor="#00000080" />
          <div className="feature-wrapper social">
            <SocialMediaSlide />
          </div>
        </>
      )}
    </div>
  );
}

const SignUpFinal = () => (
  <Layout>
    <Seo
      title="Sign Up"
      description={`Kristie Lengel offers a "no quick fix" approach to fitness and nutrition with her convenient home workouts and balanced lifestyle guidance for busy women.`}
    />
    <SignUpPage />
  </Layout>
);

export default SignUpFinal;
