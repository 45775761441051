import * as React from "react";
import { Link } from "gatsby";
import * as ROUTES from "../../constants/routes";

export default function FeatureGrid() {
  return (
    <div className="signup-features-wrapper">
      <div className="signup-features">
        <div className="feature-1">
          <div className="title-bar">
            <div>
              <Link className="light-link" to={ROUTES.FEATURE_WEEKLY_WORKOUTS}>
                5 new
                <br />
                workouts
              </Link>
            </div>
            <br />
            <div className="subtitle">every week</div>
          </div>
        </div>
        <div className="feature-2">
          <div className="title-bar">
            <div>
              <Link className="light-link" to={ROUTES.FEATURE_WORKOUT_LIBRARY}>
                50+ workout
                <br />
                video library
              </Link>
            </div>
          </div>
        </div>
        <div className="feature-3">
          <div className="title-bar">
            <div>
              <Link
                className="light-link"
                to={ROUTES.FEATURE_BALANCED_LIFESTYLE}
              >
                recipes &amp;
                <br />
                balanced lifestyle
                <br />
                guidance
              </Link>
            </div>
          </div>
        </div>
        <div className="feature-4">
          <div className="title-bar">
            <div>
              <Link
                className="light-link"
                to={ROUTES.FEATURE_COMMUNITY_SUPPORT}
              >
                #BALANCE
                <br />
                community support
              </Link>
            </div>
          </div>
        </div>
        <div className="feature-5">
          <div className="title-bar">
            <div>
              <Link
                className="light-link"
                to={ROUTES.FEATURE_PERSONAL_MEETINGS}
              >
                Personal Meetings
                <br />
                with Kristie
              </Link>
            </div>
            <br />
            <div className="subtitle">on demand</div>
          </div>
        </div>
      </div>
    </div>
  );
}
